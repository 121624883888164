import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Search, Add } from '@mui/icons-material';
import CustomModal from "../../Components/CustomModal/CustomModal";
import "./ViewInventory.css";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { InvStatus } from "./../../data/data";
import { Link, /*useNavigate*/ } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getProjectInventory, getProjectTowerList, getProjectWingList, getProjectFloorList, getSingleInventory } from "./ApiCalls";
import { Description, Heading } from "../../Components/Common";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ViewInventory = () => {

  var ind_currency = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' });

  // const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const project = queryParameters.get("project");
  // console.log(project);

  const [towertabvalue, setTowerTabValue] = React.useState(0);

  const handleChangeTowerTab = (event: React.SyntheticEvent, newTowerTabValue: string) => {
    setTowerTabValue(newTowerTabValue);
  };

  const [wingtabvalue, setWingTabValue] = React.useState(0);

  const handleChangeWingTab = (event: React.SyntheticEvent, newWingTabValue: string) => {
    setWingTabValue(newWingTabValue);
  };

  const [towerlist, setProjectTowerList] = useState([]);
  const [winglist, setProjectWingList] = useState([]);
  const [iswinglist, setIsWingList] = useState([]);
  const [floorlist, setProjectFloorList] = useState([]);
  const [projectinventory, setProjectInventory] = useState([]);
  const [singleinventory, setSingleInventory] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    
    getProjectTowerList(project).then(response => {
      // console.log(response);
      if (response.data === "No Tower Found") {
        setProjectTowerList(response.data.data)
      } else {
        setProjectTowerList(response.data)
      }
    }).catch(err => {
      console.log(err);
    });

    getProjectWingList(project).then(response => {
      // console.log(response);
      if (response.data === "No Wing Found") {
        setProjectWingList(response.data.data)
      } else {
        setProjectWingList(response.data)
        setIsWingList(response.data.filter((arr_wing)=>(arr_wing.wing!==''))?.map((arr_wing) => (arr_wing)))
      }
    }).catch(err => {
      console.log(err);
    });

    getProjectFloorList(project).then(response => {
      // console.log(response);
      if (response.data === "No Floor Found") {
        setProjectFloorList(response.data.data)
      } else {
        setProjectFloorList(response.data)
      }
    }).catch(err => {
      console.log(err);
    });
    
    getProjectInventory(project).then(response => {
      // console.log(response);
      if (response.data === "No Project Inventory Found") {
        setProjectInventory(response.data.data)
      } else {
        setProjectInventory(response.data)
      }
    }).catch(err => {
      console.log(err);
    });

  }, [project]);

  // console.log(iswinglist)

  const handleViewInventory = (pinv_id) => (event) => {
    event.preventDefault();
    const data = pinv_id;
    // alert(data);
    getSingleInventory(data).then(response => {
      console.log(response.data[0]);
      if (response.data === "No Inventory Found") {
        // setEditSource(response.data.data)
      } else {
        
        setSingleInventory(
          iswinglist.length!==0?
          [ 
            { title: 'Project Name', details: response.data[0].project_name },
            { title: 'Tower Name.', details: response.data[0].tower_name },
            { title: 'Wing', details: response.data[0].wing },
            { title: 'Floor No.', details: response.data[0].floor_no },
            { title: 'Unit No.', details: response.data[0].unit_no },
            { title: 'Apartment Type.', details: response.data[0].apartment_type },
            { title: 'Unit Size (sq.ft.)', details: parseFloat(response.data[0].unit_size).toFixed(2) },
            { title: 'Dry Balcony Size (sq.ft.)', details: parseFloat(response.data[0].dry_balcony_size).toFixed(2) },
            { title: 'Balcony Size (sq.ft.)', details: parseFloat(response.data[0].balcony_size).toFixed(2) },
            { title: 'Total Area (sq.ft.)', details: response.data[0].total_area },
            { title: 'Apartment View', details: response.data[0].apartment_view },
            { title: 'Parking', details: response.data[0].parking },
            { title: 'Agreement Value', details: ind_currency.format(response.data[0].agreement_value) },
            { title: 'Stamp Duty', details: ind_currency.format(response.data[0].stamp_duty) },
            { title: 'GST', details: ind_currency.format(response.data[0].gst) },
            { title: 'Registration Charge', details: ind_currency.format(response.data[0].registration_charge) },
            { title: 'Other Charges', details: ind_currency.format(response.data[0].other_charges) },
            { title: 'Floor Rise', details: ind_currency.format(response.data[0].floor_rise) },
            { title: 'Previlege View Charge', details: ind_currency.format(response.data[0].previlege_view_charge) },
            { title: 'Total Price', details: ind_currency.format(response.data[0].total_price) },
            { title: 'Status', details: response.data[0].inv_status },
          ]
          :
          [ 
            { title: 'Project Name', details: response.data[0].project_name },
            { title: 'Tower Name', details: response.data[0].tower_name },
            { title: 'Floor No.', details: response.data[0].floor_no },
            { title: 'Unit No.', details: response.data[0].unit_no },
            { title: 'Apartment Type.', details: response.data[0].apartment_type },
            { title: 'Unit Size (sq.ft.)', details: parseFloat(response.data[0].unit_size).toFixed(2) },
            { title: 'Dry Balcony Size (sq.ft.)', details: parseFloat(response.data[0].dry_balcony_size).toFixed(2) },
            { title: 'Balcony Size (sq.ft.)', details: parseFloat(response.data[0].balcony_size).toFixed(2) },
            { title: 'Total Area (sq.ft.)', details: response.data[0].total_area },
            { title: 'Apartment View', details: response.data[0].apartment_view },
            { title: 'Parking', details: response.data[0].parking },
            { title: 'Agreement Value', details: ind_currency.format(response.data[0].agreement_value) },
            { title: 'Stamp Duty', details: ind_currency.format(response.data[0].stamp_duty) },
            { title: 'GST', details: ind_currency.format(response.data[0].gst) },
            { title: 'Registration Charge', details: ind_currency.format(response.data[0].registration_charge) },
            { title: 'Other Charges', details: ind_currency.format(response.data[0].other_charges) },
            { title: 'Floor Rise', details: ind_currency.format(response.data[0].floor_rise) },
            { title: 'Previlege View Charge', details: ind_currency.format(response.data[0].previlege_view_charge) },
            { title: 'Total Price', details: ind_currency.format(response.data[0].total_price) },
            { title: 'Status', details: response.data[0].inv_status },
          ]
        );
        
        handleOpen();
      }
    }).catch(err => {
      console.log(err);
    });
  } 

  // console.log(projectinventory)

  const MenuParentPages = [
    {MenuPage:'All Projects', MenuPagePath:'/projects'}
  ]

  return (
    <>
      <Breadcrumb PageName="View Inventory" MenuFunction={MenuParentPages} startIcon={<ArrowBackIosNewIcon />} btnName="Back" BtnRedirect="/projects"/>
      <Box>
    
        {/* modal popup */}
        <CustomModal
          InlineCss={{ width:600}}
          ModalContentCss={{overflow:'auto',height:'400px'}}
          // BtnName=""
          OpenAddModalfun={handleViewInventory}
          handeleclosefun={handleClose}
          ModalTitle="Apartment Details"
          open={open}
          ModalContent={
            <>
              <Box>
                <Table className="apartment-details">
                  <TableBody>
                    {singleinventory?.map((sinv, sinv_id) => (
                      <TableRow key={sinv_id} >
                        <TableCell className="apartment-details-heading">
                          {sinv.title}
                        </TableCell>
                        <TableCell className="apartment-details-details">
                          {sinv.details}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </>
          }
        />

        <Box className="custom-card view-inventory-card">
          <Heading heading={project} />
          <Box sx={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
            
            <Link style={{textDecoration: 'none'}} to={"/project-inventory?project="+project}>
              <Button variant="outlined" className="advance-search-button">
                {projectinventory===undefined
                ?
                <>
                Add Inventory <Add fontSize="medium"/>
                </>
                :
                <>
                Advance Search <Search />
                </>
                }
              </Button>
            </Link>
            <Box>
              {InvStatus?.map((row, id) => (
                <Box key={id} sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', textAlign: 'right' }}>
                  <Box className={"inv_status_box " + row.value}></Box>
                  <Description description={row.value} />
                </Box>
              ))}
            </Box>
          </Box>
          
          {projectinventory!==undefined
          ?
          <Box mt={2} sx={{ width: '100%' }}>
            <Box sx={{bgcolor: 'background.paper' }}>
              <Tabs value={towertabvalue} onChange={handleChangeTowerTab} centered> 
                {towerlist?.map((arr_tower, tower_id) => (
                   <Tab className="heading tower-heading" label={arr_tower.tower_name} key={tower_id} {...a11yProps(tower_id)} />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ width: '100%' }} className="tower-box">
                {towerlist?.map((arr_tower, tower_id) => (
                 <TabPanel value={towertabvalue} key={tower_id} index={tower_id}>
                  <Box sx={{ width: '100%' }}>

                    {iswinglist.length!==0
                    ?
                    <Box sx={{bgcolor: 'background.paper' }}>
                      <Tabs value={wingtabvalue} onChange={handleChangeWingTab} centered> 
                        {winglist.filter((arr_wing) => arr_wing.tower_name === arr_tower.tower_name)?.map((arr_wing, wing_id) => (
                           <Tab className="heading wing-heading" label={arr_wing.wing} key={wing_id} {...a11yProps(wing_id)} />
                        ))} 
                      </Tabs>
                    </Box>
                    : 
                    null}

                    <Box className="wing-box" sx={{padding:'24px 0px!important'}}> 
                      {winglist.filter((arr_wing) => arr_wing.tower_name === arr_tower.tower_name)?.map((arr_wing, wing_id) => (
                        <TabPanel value={wingtabvalue} key={wing_id} index={wing_id}>
                            <Box className="floor-warpper">
                            {/* floor block started */}
                            {floorlist.filter((arr_floor) => (arr_floor.wing === arr_wing.wing && iswinglist.length!==0) || (arr_floor.tower_name === arr_tower.tower_name && iswinglist.length===0))?.map((arr_floor, floor_id) => (
                              <Box className="floor-container" key={floor_id}>
                                <Heading heading={arr_floor.floor_no}/>
                                <Box className="flat-wrapper">
                                  {projectinventory.filter((arr_projectinventory) => arr_projectinventory.floor_no === arr_floor.floor_no && arr_projectinventory.wing === arr_wing.wing && arr_projectinventory.tower_name === arr_tower.tower_name)?.map((arr_projectinventory, projectinventory_id) => (
                                    <Box key={projectinventory_id} className={"flat-container " + arr_projectinventory.inv_status}>
                                      <Box onClick={handleViewInventory(arr_projectinventory.pinv_id)} className="unit-details">
                                         
                                        {iswinglist.length!==0
                                        ?
                                         arr_projectinventory.tower_name.replace("Tower ", "") + "-" + arr_projectinventory.wing.replace("Wing ", "") + "-" + arr_projectinventory.floor_no.replace("Floor ", "F") + "-" + arr_projectinventory.unit_no
                                        :
                                         arr_projectinventory.tower_name.replace("Tower ", "") + "-" + arr_projectinventory.floor_no.replace("Floor ", "F") + "-" + arr_projectinventory.unit_no
                                        }
                                        <br />

                                        {arr_projectinventory.apartment_type}<br />
                                        {arr_projectinventory.inv_status}
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            ))}
                          </Box> 
                        </TabPanel>
                      ))}
                    </Box>
                  </Box>
                 </TabPanel> 
                ))} 
              
            </Box>
          </Box>
          :null}

        </Box>
      </Box>
    </>
  );
};

export default ViewInventory;