import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./Datatable.css";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
});

const Datatable = (props) => {

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("auto");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("400px");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);

    // const rowSelectionChange = (selectedData) => {
    //     console.log(selectedData)
    //     rowDelete()
    // }
    // const rowDelete = () => {
    //     // alert('working');
    // }

    return (
        <React.Fragment>
            <CacheProvider value={muiCache} className="custom-table">
                <ThemeProvider theme={createTheme()}>
                    <MUIDataTable
                        title={props.TabelTitle}
                        data={props.data}
                        columns={props.columns}
                        options={{
                            selectableRowsHideCheckboxes: true,
                            textLabels: {
                                body: {
                                    noMatch: props.nodatamsg,
                                },
                            },
                            search: searchBtn,
                            download: downloadBtn,
                            print: printBtn,
                            viewColumns: viewColumnBtn,
                            filter: filterBtn,
                            filterType: "dropdown",
                            responsive,
                            tableBodyHeight,
                            tableBodyMaxHeight,
                            // onTableChange: (action, state) => {
                            //     rowSelectionChange(state.data);
                            // }
                        }}
                        className="custom-datatable"
                    />
                </ThemeProvider>
            </CacheProvider>
        </React.Fragment>
    )
}

export default Datatable