// import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App'; 

// const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getProjects = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/projectname/getallprojectnamelist`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

export {getProjects}