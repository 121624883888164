// import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App'; 

// const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllProjectNameData = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/projectname/getallprojectnamedata`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setDeleteProjectName = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/projectname/deleteprojectname`, {
            prj_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllProjectNameData, setDeleteProjectName}