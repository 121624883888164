import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App'; 

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllProjectInventoryData = async(project) =>{ 
    // console.log(project)
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/getallprojectinventorydata`, {
            project_name : project,
        });
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setAddProjectInventory = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/addprojectinventory`, {
            create_dt: date,
            update_dt: date,
            project_name: data.get('project_name'),
            block_no: data.get('block_no'),
            wing: data.get('wing'),
            floor_no: data.get('floor_no'),
            unit_no: data.get('unit_no'),
            unit_size: data.get('unit_size'),
            apartment_type: data.get('apartment_type'),
            apartment_view: data.get('apartment_view'),
            parking: data.get('parking'),
            total_price: data.get('total_price').replace(/[^0-9.]/g, ''),
            inv_status: data.get('inv_status'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setDeleteProjectInventory = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/deleteprojectinventory`, {
            pinv_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllProjectInventoryData, setAddProjectInventory, setDeleteProjectInventory}