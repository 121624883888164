import React from "react";
import Grid from "@mui/material/Grid";
import Google from "../../img/source/google.png";
import Facebook from "../../img/source/facebook.png";
import Housing from "../../img/source/housing.png";
import MagicBricks from "../../img/source/magicbricks.png";
import Acres from "../../img/source/acres.png";
import Olx from "../../img/source/olx.png";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
// import SourceCountCard from "../../Components/SourceCountCard/SourceCountCard";
// import Charts from './Charts/Charts';
// import Donut from './Donut/Donut';
// import ProjectWise from "./ProjectWise/ProjectWise";
// import CrmDetail from './CrmDetail/CrmDetail';
// import LeadStatus from "./LeadStatus/LeadStatus";
// import Activity from "./Activity/Activity";
// import Attendance from "./Attendance/Attendance";

function createsData(simg, sname, scount, spercent, sdesc) {
  return {
    simg,
    sname,
    scount,
    spercent,
    sdesc,
  };
}
const lsrccard = [
  createsData(Facebook, "Facebook", 354, "+55.2%", "than yesterday"),
  createsData(Google, "Google", 123, "+10%", "than lask week"),
  createsData(Housing, "Housing", 5362, "+34.5%", "than last month"),
  createsData(MagicBricks, "MagicBricks", 234, "+20.7%", "Just updated"),
  createsData(Acres, "99 Acres", 34, "+30.7%", "Just updated"),
  createsData(Olx, "Olx", 134, "+10.7%", "Just updated")
];

const Dashboard = () => {
  return (
    <>
      <Breadcrumb PageName="Dashboard" PageDesc="Welcome To Admin Dashboard" />
      <Grid container spacing={4}>
        {lsrccard.map((row, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              {/* <SourceCountCard
                SourceImg={row.simg}
                SourceName={row.sname}
                LeadCount={row.scount}
                Percent={row.spercent}
                Description={row.sdesc}
              /> */}
            </Grid>
          )
        })}
      </Grid>
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          {/* <Charts /> */}
        </Grid> 
        <Grid item xs={12} md={4}>
          {/* <Donut /> */}
        </Grid>   
      </Grid>
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          {/* <ProjectWise /> */}
        </Grid> 
        <Grid item xs={12} md={4}>
          {/* <LeadStatus /> */}
        </Grid>   
      </Grid>
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {/* <Activity /> */}
        </Grid> 
        <Grid item xs={12} md={4}>
           {/* <Attendance /> */}
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <CrmDetail /> */}
        </Grid>   
      </Grid>
    </>
  );
};

export default Dashboard;
