import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login';

//desktop pages
import LeftSideNav from './Components/LeftSideNav/LeftSideNav';
import Dashboard from './Pages/Dashboard/Dashboard';
import ProjectInventory from './Pages/ProjectInventory/ProjectInventory';
import AddInventory from './Pages/AddInventory/AddInventory';
import Projects from './Pages/Projects/Projects';
import ProjectName from './Pages/ProjectName/ProjectName';
import AddProject from './Pages/AddProject/AddProject';
import ViewInventory from './Pages/ViewInventory/ViewInventory';
import ChangePassword from './Pages/ChangePassword/ChangePassword';

//end
import { Alert } from '@mui/material';
import axios from 'axios';

// pre-catching
import {QueryClientProvider,  QueryClient} from "react-query";
import './Components/Common.css';

// server api
export const API_URL = "https://builderbackend.rabs.support";
// export const API_URL = "http://localhost:3004";
axios.defaults.withCredentials = true; 

// pre-catching
export const queryClient = new QueryClient();

function App() {
  
  let href = window.location.href;
  let count = href.split("//").length - 1;

  // console.log(window.location.pathname)

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
          {window.location.pathname === "/" || count > 1 ? window.location.pathname = "login" : null}
          <Suspense fallback={<Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>} >
            <Routes>
              <Route path="/" element={<LeftSideNav />} >
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="project-inventory" element={<ProjectInventory />} />
                <Route path="add-inventory" element={<AddInventory />} />
                <Route path="projects" element={<Projects />} />
                <Route path="add-project" element={<AddProject />} />
                <Route path="view-inventory" element={<ViewInventory />} />
                <Route path="project-name" element={<ProjectName />} />
                {/* <Route path="total-leads" element={<TotalLeads />} />
                <Route path="add-lead" element={<AddLead />} />
                <Route path="pay-slip" element={<PaySlip />} />
                <Route path="add-user" element={<AddUser />} />
                <Route path="all-users" element={<AllUsers />} />
                <Route path="update-profile" element={<UpdateProfile />} />
                <Route path="source" element={<Source />} />
                <Route path="configuration" element={<Configuration />} />
                <Route path="geolocation" element={<Geolocation />} />
                <Route path="sub-locality" element={<SubLocality />} />
                <Route path="lead-status" element={<LeadStatus />} /> 
                <Route path="view-lead" element={<ViewLead />} />*/}
                <Route path="change-password" element={<ChangePassword />} />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="*" element={<Navigate component={Link} to="login" />} />
            </Routes>
          </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
