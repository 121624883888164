import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App'; 

const systemdatetime = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllProjectsData = async() =>{
  // console.log(project)
  try{
      const response = await Axios.post(`${API_URL}/projectname/getallprojectsdata`);
      return response;
  
  }catch(err){
  
      return err;
  
  }
}  

const getAllParkingList = async() =>{
  try{

      const response = await Axios.post(`${API_URL}/parking/getallparkinglist`);
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const getProjectTowersData = async(prj_id) =>{
  // console.log(prj_id)
  try{
      const response = await Axios.post(`${API_URL}/projectname/getprojecttowersdata`,{
          prj_id: prj_id,
      });
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const getTowerWingsData = async(t_id) =>{
  // console.log(t_id)
  try{
      const response = await Axios.post(`${API_URL}/projectname/gettowerwingsdata`,{
          t_id: t_id,
      });
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const getTowerConfigurationsData = async(t_id) =>{
  // console.log(t_id)
  try{
      const response = await Axios.post(`${API_URL}/projectname/gettowerconfigurationsdata`,{
          t_id: t_id,
      });
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const getTowerData = async(t_id) =>{
  // console.log(t_id)
  try{
      const response = await Axios.post(`${API_URL}/projectname/gettowerdata`,{
          t_id: t_id,
      });
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const getConfigurationData = async(confi_id) =>{
  // console.log(confi_id)
  try{
      const response = await Axios.post(`${API_URL}/projectname/getconfigurationdata`,{
          confi_id: confi_id,
      });
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const setAddProjectInventory = async(data) =>{

    const total_area = (parseFloat(data.get('unit_size'))+parseFloat(data.get('dry_balcony_size'))+parseFloat(data.get('balcony_size'))).toFixed(2);
    const agreement_value = (total_area*parseFloat(data.get('rate_per_sqft'))).toFixed(2);
    const stamp_duty = parseFloat(agreement_value*0.07).toFixed(2);
    const gst = parseFloat(agreement_value*0.01).toFixed(2);
    const registration_charge = parseFloat(data.get('registration_charge').replace(/[^0-9.]/g, '')).toFixed(2);
    const other_charges = parseFloat(data.get('other_charges').replace(/[^0-9.]/g, '')).toFixed(2);
    const floor_rise = parseFloat(data.get('floor_rise').replace(/[^0-9.]/g, '')).toFixed(2);
    const previlege_view_charge = parseFloat(data.get('previlege_view_charge').replace(/[^0-9.]/g, '')).toFixed(2);
    const total_price = (parseFloat(agreement_value)+parseFloat(stamp_duty)+parseFloat(gst)+parseFloat(registration_charge)+parseFloat(other_charges)+parseFloat(floor_rise)+parseFloat(previlege_view_charge)).toFixed(2);

    try{
        const response = await Axios.post(`${API_URL}/projectinventory/addprojectinventory`, {
            create_dt: systemdatetime,
            update_dt: systemdatetime,
            prj_id: data.get('prj_id'),
            project_name: data.get('project_name'),
            tower_name: data.get('tower_name'),
            wing: data.get('wing'),
            floor_no: data.get('floor_no'),
            unit_no: data.get('unit_no'),
            apartment_type: data.get('apartment_type'),
            unit_size: data.get('unit_size'),
            dry_balcony_size: data.get('dry_balcony_size'),
            balcony_size: data.get('balcony_size'),
            total_area: total_area,
            apartment_view: data.get('apartment_view'),
            parking: data.get('parking'),
            rate_per_sqft: data.get('rate_per_sqft'),
            agreement_value: agreement_value,
            stamp_duty: stamp_duty,
            gst: gst,
            registration_charge: registration_charge,
            other_charges: other_charges,
            floor_rise: floor_rise,
            previlege_view_charge: previlege_view_charge,
            total_price: total_price,
            inv_status: data.get('inv_status'),
        });

        return response;

    }catch(err){
 
      return err;

    }
}

export { getAllProjectsData, getAllParkingList, getProjectTowersData, getTowerWingsData, getTowerConfigurationsData, getTowerData, getConfigurationData, setAddProjectInventory }