import React, {useState, useEffect} from "react";
// import Button from "@mui/material/Button";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip, } from "@mui/material";
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import Datatable from "../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllProjectNameData, setDeleteProjectName} from "./ApiCalls";
// import { useNavigate } from "react-router-dom";

const ProjectName = () => {
    
    // const navigate = useNavigate();
    const [projectnamedata, setAllProjectNameData] = useState([]);

    useEffect(() => {
        getAllProjectNameData().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllProjectNameData(response.data.data)
            } else {
                setAllProjectNameData(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleDeleteProjectName = (prj_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the Project?')) {
            const data = prj_id;
            setDeleteProjectName(data)
                .then((response) => {
                    console.log(response);
                    // navigate("/projectname");
                    if (response.data === "Project Name deleted") {
                        // handleClose();
                        getAllProjectNameData().then(response => {
                            console.log(response);
                            if (response.data === "No Data Found") {
                                setAllProjectNameData(response.data.data)
                            } else {
                                setAllProjectNameData(response.data)
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        toast.success("Project Deleted Succefully");
                    } else {
                        toast.error("Failed! Not Deleted");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const columns = [
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit"
                                component="label"
                                onClick={
                                    handleEditProjectName(projectnamedata[dataIndex].prj_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Delete"
                                component="label"
                                onClick={
                                    handleDeleteProjectName(projectnamedata[dataIndex].prj_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: "prj_id",
            label: "Project Id",
        },
        {
            name: "create_dt",
            label: "Posted On",
        },
        {
            name: "update_dt",
            label: "Updated On",
        },
        {
            name: "project_name",
            label: "Project Name",
        },
        {
            name: "builder_name",
            label: "Builder Name",
        },
        // {
        //     name: "featured_image",
        //     label: "Featured Image",
        // },
        {
            name: "tower_type",
            label: "Tower Type",
        },
    ];

    return (
        <>
            <ToastContainer />

            <Breadcrumb PageName="All Projects" startIcon={<AddIcon />} btnName="Add PRoject" BtnRedirect="/add-project" />

            <Datatable TabelTitle="Add Project Name" data={projectnamedata?.map(project => {
                return [
                    project,
                    project.prj_id,
                    // dayjs(project.create_dt).format('DD-MM-YYYY HH:mm:ss'),
                    dayjs().format('YYYY-MM-DD') === dayjs(project.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(project.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(project.create_dt).format('hh:MM a'),
                    dayjs().format('YYYY-MM-DD') === dayjs(project.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(project.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(project.update_dt).format('hh:MM a'),
                    project.project_name,
                    project.builder_name,
                    // <img src="project.featured_image"/>,
                    project.tower_type,
                ]
            })} columns={columns} />

        </>
    );
};
export default ProjectName;
