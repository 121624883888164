import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip} from "@mui/material";
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Datatable from "../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ProjectInventory.css";
import { getAllProjectInventoryData,  setDeleteProjectInventory} from "./ApiCalls";
// import { Link, useNavigate } from "react-router-dom";

const ProjectInventory = () => {

    var ind_currency = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' });

    // const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const project = queryParameters.get("project");
    // console.log(project);

    const [projectinventory, setAllProjectInventory] = useState([]);

    useEffect(() => {

        getAllProjectInventoryData(project).then(response => {
            console.log(response);
            if (response.data === "No Project Inventory Found") {
                setAllProjectInventory(response.data.data)
            } else {
                setAllProjectInventory(response.data)
            }
        }).catch(err => {
            console.log(err);
        });

    }, [project]);

    const handleDeleteProjectInventory = (pinv_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the This Project Inventory?')) {
            const data = pinv_id;
            setDeleteProjectInventory(data)
                .then((response) => {
                    if (response.data === "Project Inventory deleted") {
                        getAllProjectInventoryData(project).then(response => {
                            // console.log(response);
                            if (response.data === "No Data Found") {
                                setAllProjectInventory(response.data.data)
                            } else {
                                setAllProjectInventory(response.data)
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        toast.success("Project Invntory Deleted Succefully");
                    } else {
                        toast.error("Failed! Not Deleted");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const columns = [
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit"
                                component="label"
                                onClick={
                                    handleEditSource(projectinventory[dataIndex].pinv_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Delete"
                                component="label"
                                onClick={
                                    handleDeleteProjectInventory(projectinventory[dataIndex].pinv_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: "pinv_id",
            label: "PInv Id",
        },
        {
            name: "create_dt",
            label: "Posted On",
        },
        {
            name: "update_dt",
            label: "Updated On",
        },
        {
            name: "project_name",
            label: "Project Name",
        },
        {
            name: "tower_name",
            label: "Tower Name",
        },
        {
            name: "wing",
            label: "Wing",
        },
        {
            name: "floor_no",
            label: "Floor No.",
        },
        {
            name: "unit_no",
            label: "Unit No.",
        },
        {
            name: "apartment_type",
            label: "Apartment Type",
        },
        {
            name: "unit_size",
            label: "Unit Size (sq.ft.)",
        },
        {
            name: "dry_balcony_size",
            label: "Dry Balcony Size (sq.ft.)",
        },
        {
            name: "balcony_size",
            label: "Balcony Size (sq.ft.)",
        },
        {
            name: "total_area",
            label: "Total Area (sq.ft.)",
        },
        {
            name: "apartment_view",
            label: "Apartment View",
        },
        {
            name: "parking",
            label: "Parking",
        },
        {
            name: "agreement_value",
            label: "Agreement Value",
        },
        {
            name: "stamp_duty",
            label: "Stamp Duty",
        },
        {
            name: "gst",
            label: "GST",
        },
        {
            name: "registration_charge",
            label: "Registration Charge",
        },
        {
            name: "other_charges",
            label: "Other Charges",
        },
        {
            name: "floor_rise",
            label: "Floor Rise",
        },
        {
            name: "previlege_view_charge",
            label: "Previlege View Charge",
        },
        {
            name: "total_price",
            label: "Total Price",
        },
        {
            name: "inv_status",
            label: "Status",
        },
    ];
    
    const MenuParentPages = [
        {MenuPage:'Projects', MenuPagePath:'/projects'}, 
        // {MenuPage:'View Inventory', MenuPagePath:'/view-inventory?project='+project}
    ]

    return (
        <>
            <ToastContainer />

            <Breadcrumb PageName="Project Inventory" MenuFunction={MenuParentPages} startIcon={<AddIcon />} btnName="Add Inventory" BtnRedirect="/add-inventory" /*OpenAddModalfun={ModalOpenAdd}*/ />

            <Datatable TabelTitle="Add Project Inventory" data={projectinventory?.map(src => {
                return [
                    src,
                    src.pinv_id,
                    // dayjs(src.create_dt).format('DD-MM-YYYY HH:mm:ss'),
                    dayjs().format('YYYY-MM-DD') === dayjs(src.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(src.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(src.create_dt).format('hh:MM a'),
                    dayjs().format('YYYY-MM-DD') === dayjs(src.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(src.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(src.update_dt).format('hh:MM a'),
                    src.project_name,
                    src.tower_name,
                    src.wing,
                    src.floor_no,
                    src.unit_no,
                    src.apartment_type,
                    parseFloat(src.unit_size).toFixed(2),
                    parseFloat(src.dry_balcony_size).toFixed(2),
                    parseFloat(src.balcony_size).toFixed(2),
                    parseFloat(src.total_area).toFixed(2),
                    src.apartment_view,
                    src.parking,
                    ind_currency.format(src.agreement_value),
                    ind_currency.format(src.stamp_duty),
                    ind_currency.format(src.gst),
                    ind_currency.format(src.registration_charge),
                    ind_currency.format(src.other_charges),
                    ind_currency.format(src.floor_rise),
                    ind_currency.format(src.previlege_view_charge),
                    ind_currency.format(src.total_price),
                    // src.total_price,
                    // <span className={
                    // src.inv_status==='Blocked'
                    // ? "inv_status blocked"
                    // : src.inv_status==='Reserved'
                    // ? "inv_status reserved"
                    // : src.inv_status==='Sold'
                    // ? "inv_status sold"
                    // : src.inv_status==='Available'
                    // ? "inv_status available"
                    // : null 
                    // }>{src.inv_status}</span>
                    src.inv_status
                ]
            })} columns={columns} />

        </>
    );
};
export default ProjectInventory;
