import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Button from "@mui/material/Button";
import "./Breadcrumb.css";
import {Link} from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb:2}}>
      <Box>

        <Link to="/" className="hometext">
          <HomeIcon />
        </Link>
        {props.MenuParentPages!==null?
        <>
        {props.MenuFunction?.map((row, id) => (
          <span key={id}>
           {row.MenuPagePath!==null
            ?
            <>
            <span className="separator">/</span>
            <Link to={row.MenuPagePath} className="hometext">
            {row.MenuPage}
            </Link>
            </>
            :null}   
          </span>   
        ))}
        </>
        :
        null}
        <span className="separator">/</span>
        <Typography variant="span" className="hometext currentpagename">
        {props.PageName}
        </Typography>
       
        <Typography variant="h2" className="page-title">
          {props.PageName}
        </Typography>

      </Box>
      
      {props.BtnRedirect !== undefined ?
        <Button component={Link} to={props.BtnRedirect} onClick={props.OpenModalfun} startIcon={props.startIcon} variant="contained">{props.btnName}</Button>
        : 
      props.OpenAddModalfun !== undefined ? 
        <Button variant="contained" sx={{ mx: 1 }} startIcon={props.startIcon} onClick={props.OpenAddModalfun}>{props.btnName}</Button>
        :null
      }
    </Box>
  );
};

export default Breadcrumb;
