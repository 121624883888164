import React, {useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Grid, Typography } from "@mui/material";
import CurrencyInput from 'react-currency-input-field';
import PhoneInput from 'react-phone-input-2'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import 'react-phone-input-2/lib/style.css'
import "./Common.css";

const animatedComponents = makeAnimated();
export const CustomInputField = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      required={props.required}
      accept={props.accept}
      className="custom-input"
      onChange={props.onchange}
    />
  );
};

export const CustomButton = (props) => {
  return (
    <Button 
      variant={props.variant} 
      size={props.size} 
      endIcon={props.endIcon} 
      value={props.value}
      onChange={props.onchange}
    />
  );
};

export const CustomMultiSelectFiled = (props) => {
  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      defaultValue={[props.defaultvalue]}
      isMulti
      options={props.options}
    />
  );
};

// export const CustomSelectFiled = (props) => {
//   return (
//     <Select
//       isClearable
//       name={props.name}
//       options={props.options}
//       value={props.Value}
//       required={props.required}
//       defaultValue={props.defaultValue}
//       isDisabled = {props.disabled}
//       // defaultValue={{ label: props.defaultVLlable, value: props.defaultVLvalue }}
//       onChange={props.onChange}
//     />
//   );
// };

export const CustomSelectFiled = (props) => {
  const [val, setVal] = React.useState();
  const [newVal, setNewVal] = React.useState();

  const handleChange = (value) => {
    setNewVal(value);
    return props.onChange(value)
  }
  
  React.useEffect(() => { 
    if(props.defaultVal && props.defaultVal !== 'undefined'){ 
      setNewVal([{ label: props.defaultVal, value: props.defaultVal }])  
    }
  }, [props])

  return (
    <Select
      isClearable
      name={props.name}
      options={props.options}
      value={
        newVal ? newVal : val
      }
      required={props.required}
      isOptionDisabled={props.optionDisbaled} 
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      onChange={
        handleChange
      }
    />
  );
};

export const CustomTextareaField = (props) => {
  return (
    <textarea
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      className="custom-textarea"
      rows={4}
    ></textarea>
  );
};

export const CustomCurrencyInput = (props) => {
  return(
    <CurrencyInput 
        name={props.name}
        placeholder={props.placeholder}
        className="custom-currency-input"
        decimalsLimit={props.decimalsLimit}
        intlConfig={props.intlConfig}
        prefix={props.prefix}
        value={props.value}
        onValueChange={props.onValueChange}
        decimalSeparator={props.decimalSeparator}
        groupSeparator={props.groupSeparator}
    />
  );
};

export const CustomFormGroup = (props) => {
  return (
    <>
      <Grid item xs={6} md={2} className="custom-from-group" sx={{marginTop:props.mtprop}}>
        <Typography variant="h6" className="custom-form-label">
          {props.formlabel} <span className="required-label">{props.star}</span>
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        {props.FormField}
      </Grid>
    </>
  );
};

export const CustomMobileFiled = (props) => {
  return (
    <>
      <Grid item xs={6} md={2} className="custom-from-group">
        <Typography variant="h6" className="custom-form-label">
          {props.formlabel} <span className="required-label">{props.star}</span>
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
      <PhoneInput 
        autoFormat
        enableSearch
        country="in"
        placeholder="0000"
        preferredCountries={['in','ae','gb','us']}
        inputProps={{
          name: props.Inputname,
          required: props.InputRequired,
          autoFocus: true
        }}
        // containerClass=""
        // inputClass=""
        // buttonClass=""
        // dropdownClass=""
        // searchClass=""
        containerStyle={{width: '100%'}}
        inputStyle={{width: '100%'}}
        // buttonStyle={{width: '100%'}}
        // dropdownStyle={{width: '100%'}}
        // searchStyle={{width: '100%'}}
        //value={this.state.phone}
        // onChange={phone => console.log({ phone })}
        // onChange={phone => this.setState({ phone })}
        />
        {/* <Select
          className="ccode-select"
          isClearable
          options={props.options}
          value={props.Value}
          name={props.ccode_name}
          required={props.required}
          onChange={props.onChange}
          defaultValue={props.defaultvalue}
        /> */}
      </Grid>
      {/* <Grid item xs={6} md={3}>
        <input
          maxLength={15}
          type={props.type}
          name={props.mob_name}
          placeholder={props.placeholder}
          required={props.mrequired}
          className="custom-input"
        />
      </Grid> */}
    </>
  );
};

export const CustomMuiTextField = (props) => {
  
  // const [name, setName] = React.useState('');

  return (
    <TextField
      size="small"
      id="outlined-controlled"
      label={props.inputLabel}
      name={props.inputname}
      required={props.required}
      sx={{ width: '100%' }}
      // value={name}
      value={props.inputValue}
      onChange={props.inputOnChange}
      // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      //   console.log(event.target.value)
      //   setNameData(event.target.value);
      // }}
    />
  );
};

export const CustomSingleSelectAutocomplete = (props) => {
  
  const [value, setValue] = React.useState(props.defaultValue);
  const [inputValue, setInputValue] = React.useState('');
  
  return (
    <Autocomplete
      size="small"
      value={value !== null ? value:null }
      onChange={
        (event, newValue) => {
          // console.log(newValue)
          setValue(newValue);
            if(props.inputRole==='dynamic'){
              return props.onChange(newValue);
            }
        }
      }
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={props.options}
      sx={{ width: '100%' }}
      renderInput={(params) => 
        <TextField {...params} 
            variant={props.inputVariant ? "outlined" : "standard"} 
            label={props.inputLabel}
            className="inputautocomplete"
            required={props.inputRequired}
            name={props.inputname}
        />
      }
    />
  );
};

export const CustomMultiSelectAutocomplete = (props) => {
  
  const [value, setValue] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Autocomplete
        multiple
        id={props.id}
        size="small"
        limitTags={1}
        options={props.options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        style={{ width: '100%'}}
        renderInput={(params) => (
          <TextField {...params} label={props.inputLabel} placeholder={props.inputPlaceholder} required={value.length===0?props.inputrequired:false}/>
        )}

        onChange={(event, newValue) => { 
          setValue(newValue)
        }}

      />
      <input type='hidden' name={props.inputname} value={value?.map((key) => (key.title))} />
    </>
  );
};

export const CustomFormLabel = (props) => {
  return (
    <>
      <Typography variant="h6" className="custom-form-label">
        {props.formlabel} <span className="required-label">{props.star}</span>
      </Typography>
    </>
  );
};

export const Heading = (props) => {
  return (
    <>
      <Typography variant="h6" className="heading">
        {props.heading}
      </Typography>
    </>
  );
};


export const Description = (props) => {
  return (
    <>
      <Typography variant="h6" className="description">
        {props.description}
      </Typography>
    </>
  );
};

