import React, { useState, } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import {
    CustomInputField,
    CustomFormGroup,
} from "./../../Components/Common";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setChangePassword } from "./ApiCalls";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
    
    const navigate = useNavigate();
    
    const handleChangePassword = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("user_id"));
        // alert(data.get("current_password"));
        // alert(data.get("new_password"));
        // alert(data.get("confirm_password"));
        if (data.get("new_password") === data.get("confirm_password")) {

            setChangePassword(data)
                .then((response) => {
                    // console.log(response);
                    if (response.data === "Password Updated") {
                        toast.success("Password Changes Successfully");

                        setTimeout(() => {
                            navigate('/')
                        }, 2000)
                        
                    } else {
                        toast.error("Your current password is incorrect; it's required to change the Password.");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {
            toast.warn("New Password and Confirm Password Must Be Same");
        }

    };

    return (
        <>

            <Breadcrumb PageName="Change Password" />
            <ToastContainer />
            <Box className="custom-card">
                <Typography variant="h4" className="custom-card-head">
                    Change Password :-
                </Typography>
                <Box p={3} component="form" autoComplete="off" onSubmit={handleChangePassword} method="post" >
                    <Grid container spacing={2} mb={2}>
                        <CustomInputField
                            type="hidden"
                            name="user_id"
                            value="130"
                            placeholder="Enter User Id"
                            required="required"
                        />
                        <CustomFormGroup
                            formlabel="Current Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="text"
                                    name="current_password"
                                    placeholder="Enter Current Password..."
                                    required="required"
                                />
                            }
                        />
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <CustomFormGroup
                            formlabel="New Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="password"
                                    name="new_password"
                                    placeholder="Enter New Password"
                                    required="required"
                                />
                            }
                        />
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <CustomFormGroup
                            formlabel="Confirm Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="password"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    required="required"
                                />
                            }
                        />
                    </Grid>
                    <Button variant="contained" type="submit" disableElevation>
                        Change Password
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AddUser;
