import React, { useEffect, useState } from "react";
import {
  Box,
  // Tabs,
  // Tab,
  // Typography,
  // Grid,
  // Button,
  // Radio,
  // RadioGroup,
  // FormControlLabel,
  Card,
  CardContent,
  CardMedia,
  Stack,
  // Chip,
  // Skeleton
} from "@mui/material";
import { /*PropaneSharp, ZoomIn*/ } from '@mui/icons-material';
import "./Projects.css";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getProjects } from "./ApiCalls";
import { API_URL } from '../../App'; 
import { Description, Heading } from "../../Components/Common";

// interface ProjectsProps {
//   loading?: boolean;
// }

const Projects = () => {

  // const { loading = false } = props;

  const navigate = useNavigate();

  // const ProjectImg = 'https://is1-2.housingcdn.com/4f2250e8/13322cd022ed30659d8056a23cd6d8ee/v0/large/lodha_bel_air-jogeshwari_west-mumbai-lodha_group.jpeg.webp';     

  const [projects, setProjects] = useState([]);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  useEffect(() => {

    getProjects().then(response => {
    //   console.log(response);
      if (response.data === "No Data Found") {
        setProjects(response.data.data)
      } else {
        setProjects(response.data)
      }
    }).catch(err => {
      console.log(err);
    });
  }, []);

  const handleViewProject = (project_name) => (id) => {
      id.preventDefault();  
      const project = project_name;
      // alert(project);
      navigate("../view-inventory?project="+project);
  };

  return (
    <>
      <Breadcrumb PageName="All Projects" MenuPagePath={null}/>
      <Box>
        <Box className="view-project-card">
          {/* <Heading heading="All Projects" /> */}
          {/* <Skeleton animation="wave" variant="rectangular" width={210} height={60} /> */}
          <Box className="project-grid">
            
            {projects?.map((prj, prj_id) => (
              <Card sx={{ display: "flex",m:"auto",justifyContent:"center",cursor:"pointer",boxShadow:'0px 0px 10px #ccc',width:'100%'}} 
                key={prj_id} 
                onClick={handleViewProject(prj.project_name)}>
                  <Box sx={{ display: "flex",flexDirection: "column",width:"100%" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                        <Heading heading={prj.project_name} />
                        <Description description={"By "+prj.builder_name} sx={{lineHeight:'18px'}}/>
                    </CardContent>
                    <Stack direction="row" spacing={1} sx={{mb:2,ml:1}}>
                        {/* <Chip
                            label="View"
                            variant="outlined" 
                            onClick={handleViewProject}
                        />
                        <Chip
                            description 
                            label="Marketing Materials"
                            variant="outlined"
                            onClick={handleViewMMaterial}
                        /> */}
                    </Stack>
                  </Box>
                  <CardMedia
                    component="img"
                    sx={{ width: "100px", height:"100px"}}
                    // image={prj.featured_image}`${API_URL}/projectname/getallprojectnamelist`
                    image={`${API_URL}/Uploads/Projects/${prj.featured_image}`}
                    alt="project-img"
                  />
              </Card>
            ))}

            </Box>
        </Box>
      </Box>
    </>
  );
};

export default Projects;