import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { InvStatus } from "../../data/data";
import {
//   CustomSelectFiled,
  CustomInputField,
//   CustomFormLabel,
  CustomFormGroup,
//   CustomTextareaField,
//   CustomMobileFiled,
//   CustomMultiSelectFiled
// CustomMuiTextField,
CustomCurrencyInput,
CustomSingleSelectAutocomplete,
// CustomMultiSelectAutocomplete,
// Description,
} from "./../../Components/Common";
import "./AddProject.css";
import { ToWords } from 'to-words';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getAllProjectsData, getAllParkingList, getProjectTowersData, getTowerWingsData, getTowerConfigurationsData, getTowerData, getConfigurationData, setAddProjectInventory } from "./ApiCalls";

const AddInventory = () => {

    const navigate = useNavigate();

    const [allprojectsdata, setAllProjectsData] = useState([]);
    const [projectid, setProjectID] = useState();
    const [towerslist, setTowersList] = useState([]);
    const [wingslist, setWingsList] = useState([]);
    const [floorlist, setFloorList] = useState([]);
    const [configurationlist, setConfigurationList] = useState([]);
    const [apartmenttype, setApartmentType] = useState();
    const [unitsize, setUnitSize] = useState();
    const [drybalconysize, setDryBalconySize] = useState();
    const [balconysize, setBalconySize] = useState();
    const [apartmentviewlist, setApartmentViewList] = useState([]);
    const [parkinglist, setAllParkingList] = useState([]);
    const [ratepersqft, setRatePerSqft] = useState();
    const [registrationcharge, setRegistrationCharge] = useState(30000);
    const [othercharges, setOtherCharges] = useState(0);
    const [floorrise, setFloorRise] = useState(0);
    const [previlegeviewcharge, setPrevilegeViewCharge] = useState(0);


    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Rupee',
            plural: 'Rupees',
            symbol: '₹',
            fractionalUnit: {
              name: 'Paisa',
              plural: 'Paise',
              symbol: '',
            },
          }
        }
    });  

    const handleAddProjectInventory = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("project_name"));
        setAddProjectInventory(data) 
            .then((response) => {
                // console.log(response);
                if (response.data === "Project Inventory Added") {

                    toast.success("Project Invetory Added Successfully");

                    setTimeout(() => { 
                        navigate("/project-inventory")
                    }, 2000)  

                } else {
                    toast.error("Failed! to add, Project Inventory Allready Exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };     

    const handleFetchProjectData = (event) => {
        // console.log('fetch');
        if(event !== null ){
            // console.log(event) 
            let prj_id=event.id;
            setProjectID(prj_id);
            getProjectTowersData(prj_id).then(response => {
                // console.log(response);
                if (response.data === "No Data Found") {
                    setTowersList(response.data.data)
                } else {
                    setTowersList(response.data.map((towerslist)=>({label: towerslist.tower_name, id: towerslist.t_id})))
                }
            }).catch(err => {
                console.log(err);
            });
        }    
    }

    const handleFetchTowerData = (event) => {
        // console.log('fetch');
        if(event !== null ){
            // console.log(event) 
            let t_id=event.id;
            
            getTowerWingsData(t_id).then(response => {
                // console.log(response);
                if (response.data === "No Data Found") {
                    setWingsList(response.data.data)
                } else {
                    setWingsList(response.data.map((wingslist)=>(wingslist.wing_name)))

                }
            }).catch(err => {
                console.log(err);
            });

            getTowerConfigurationsData(t_id).then(response => {
                console.log(response);
                if (response.data === "No Data Found") {
                    setConfigurationList(response.data.data)
                } else {
                    setConfigurationList(response.data.map((configurationlist)=>({label:configurationlist.apartment_type+" | Area: "+configurationlist.unit_size+" | DB: "+configurationlist.dry_balcony_size+" | B: "+configurationlist.balcony_size, id:configurationlist.confi_id})))

                }
            }).catch(err => {
                console.log(err);
            });

            getTowerData(t_id).then(response => {
                console.log(response);
                if (response.data === "No Data Found") {
                    setFloorList(response.data.data)
                    setApartmentViewList(response.data.data)
                } else {

                    let total_floors = ["Ground"];
                    for (var i=1;i<=response.data[0].total_floors;i++){
                       if(i<10){ 
                         total_floors.push("Floor 0"+i);
                       }else{
                         total_floors.push("Floor "+i);
                       }
                    }

                    // console.log(total_floors);
                    setFloorList(total_floors.map((floorlist, index)=>(floorlist)))
                    setApartmentViewList(response.data[0].apartment_view.split(',').map((apartmentview, index)=>(apartmentview)))
                    setRatePerSqft(response.data[0].rate_per_sqft)

                }
            }).catch(err => {
                console.log(err);
            });


        }    
    }

    const handleFetchConfigurationData = (event) => {
        // console.log('fetch');
        if(event !== null ){
            // console.log(event) 
            let confi_id=event.id;

            getConfigurationData(confi_id).then(response => {
                // console.log(response);
                if (response.data === "No Data Found") {
                    setApartmentType(response.data.data);
                    setUnitSize(response.data.data);
                    setDryBalconySize(response.data.data);
                    setBalconySize(response.data.data);
                } else {
                    setApartmentType(response.data[0].apartment_type);
                    setUnitSize(response.data[0].unit_size);
                    setDryBalconySize(response.data[0].dry_balcony_size);
                    setBalconySize(response.data[0].balcony_size);

                }
            }).catch(err => {
                console.log(err);
            });
        }    
    }

    useEffect(() => {

        getAllProjectsData().then(response => {
            // console.log(response);
            if (response.data === "No Data Found") {
                setAllProjectsData(response.data.data)
            } else {
          
                setAllProjectsData(response.data?.map((allprojectsdata)=>({label: allprojectsdata.project_name, id: allprojectsdata.prj_id})))

            }
          }).catch(err => {
            console.log(err);
        });

        getAllParkingList().then(response => {
            // console.log(response);
            if (response.data === "No Data Found") {
                setAllParkingList(response.data.data)
            } else {
                setAllParkingList(response.data?.map((parkinglist)=>(parkinglist.parking)))
            }
        }).catch(err => {
            console.log(err);
        });

    }, []);

    // console.log(allprojectsdata)
    
    const MenuParentPages = [
        {MenuPage:'Projects', MenuPagePath:'/projects'}, 
    ]
    
    return (
    <>
      <ToastContainer />
      <Breadcrumb PageName="Add Inventory" MenuFunction={MenuParentPages} startIcon={<ArrowBackIosNewIcon />} btnName="Back" BtnRedirect="/project-inventory"/> 

      <Box p={1} component="form" autoComplete="off" onSubmit={handleAddProjectInventory} method="post">
        
        <Box className="custom-card">
          <Typography variant="h4" className="custom-card-head">
            Inventory Details :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Project"
                    star="*"
                    FormField={
                        <>
                        <CustomSingleSelectAutocomplete
                            options={allprojectsdata}
                            inputLabel="Project"
                            defaultValue={null}
                            inputVariant
                            inputname="project_name"
                            inputRequired={true}
                            inputRole="dynamic"
                            onChange={handleFetchProjectData}
                        />
                        <input type="hidden" name="prj_id" value={projectid} />
                        </>
                    }
                />   

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Tower"
                    star="*"
                    FormField={
                        <>
                        <CustomSingleSelectAutocomplete
                            options={towerslist}
                            defaultValue={null}
                            inputLabel="Tower"
                            inputVariant
                            inputname="tower_name"
                            inputRequired={true}
                            inputRole="dynamic"
                            onChange={handleFetchTowerData}
                        />
                        </>
                    }
                /> 
                
                {wingslist!==undefined
                ?
                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Wing"
                    star="*"
                    FormField={
                        <CustomSingleSelectAutocomplete
                            options={wingslist}
                            defaultValue={null}
                            inputLabel="Wing"
                            inputVariant
                            inputname="wing"
                            inputRequired={true}
                            // inputRole="dynamic"
                            // onChange={}
                        />
                    }
                /> 
                :
                <input type="hidden" name="wing" value=""/>
                }    

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Floor No."
                    star="*"
                    FormField={
                        <CustomSingleSelectAutocomplete
                            options={floorlist}
                            defaultValue={null}
                            inputLabel="Floor No."
                            inputVariant
                            inputname="floor_no"
                            inputRequired={true}
                            // inputRole="dynamic"
                            // onChange={}
                        />
                    }
                />  

                <CustomFormGroup
                mtprop="0rem"
                formlabel="Unit No."
                star="*"
                FormField={
                        <CustomInputField
                            type="number"
                            name="unit_no"
                            placeholder="Enter Unit No. eg. 101"
                            required="required"
                        />
                    }
                />

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Configuration"
                    star="*"
                    FormField={
                        <>
                        <CustomSingleSelectAutocomplete
                            options={configurationlist}
                            defaultValue={null}
                            inputLabel="Configuration"
                            inputVariant
                            inputname="configuration"
                            inputRequired={true}
                            inputRole="dynamic"
                            onChange={handleFetchConfigurationData}
                        />
                        <input type="hidden" name="apartment_type" value={apartmenttype} />
                        <input type="hidden" name="unit_size" value={unitsize} />
                        <input type="hidden" name="dry_balcony_size" value={drybalconysize} />
                        <input type="hidden" name="balcony_size" value={balconysize} />
                        </>
                    }
                />

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Apartment View"
                    star="*"
                    FormField={
                        <CustomSingleSelectAutocomplete
                            options={apartmentviewlist}
                            defaultValue={null}
                            inputLabel="Apartment View"
                            inputVariant
                            inputname="apartment_view"
                            inputRequired={true}
                            // inputRole="dynamic"
                            // onChange={}
                        />
                    }
                />  

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Parking"
                    star="*"
                    FormField={
                        <CustomSingleSelectAutocomplete
                            options={parkinglist}
                            defaultValue={null}
                            inputLabel="Parking"
                            inputVariant
                            inputname="parking"
                            inputRequired={true}
                            // inputRole="dynamic"
                            // onChange={}
                        />
                    }
                />  

                <input type="hidden" name="rate_per_sqft" value={ratepersqft} />

                <CustomFormGroup
                    // mtprop="0rem"
                    formlabel="Registration Charge"
                    star="*"
                    FormField={ 
                        <>
                        <CustomCurrencyInput 
                        name="registration_charge"
                        placeholder="Enter Registation Charge"
                        className="PricingInput"
                        decimalsLimit={2}
                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                        prefix=" "
                        value={registrationcharge}
                        onValueChange={(value) => value ? setRegistrationCharge(value) : setRegistrationCharge('')}
                        decimalSeparator="."
                        groupSeparator=","
                        />
                        <p className="towards-currency-input-text">
                            {registrationcharge ? toWords.convert(registrationcharge ? registrationcharge : 0, { currency: true })+"." : null}
                        </p>
                        </>
                    }
                /> 

                <CustomFormGroup
                    // mtprop="0rem"
                    formlabel="Other Charges"
                    star="*"
                    FormField={
                        <>
                        <CustomCurrencyInput 
                        name="other_charges"
                        placeholder="Enter Other Charges"
                        className="PricingInput"
                        decimalsLimit={2}
                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                        prefix=" "
                        value={othercharges}
                        onValueChange={(value) => value ? setOtherCharges(value) : setOtherCharges('')}
                        decimalSeparator="."
                        groupSeparator=","
                        />
                        <p className="towards-currency-input-text">
                            {othercharges ? toWords.convert(othercharges ? othercharges : 0, { currency: true })+"." : null}
                        </p>
                        </>
                    }
                />  

                <CustomFormGroup
                    // mtprop="0rem"
                    formlabel="Floor Rise"
                    star="*"
                    FormField={
                        <>
                        <CustomCurrencyInput 
                        name="floor_rise"
                        placeholder="Enter Floor Rise"
                        className="PricingInput"
                        decimalsLimit={2}
                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                        prefix=" "
                        value={floorrise}
                        onValueChange={(value) => value ? setFloorRise(value) : setFloorRise('')}
                        decimalSeparator="."
                        groupSeparator=","
                        />
                        <p className="towards-currency-input-text">
                            {floorrise ? toWords.convert(floorrise ? floorrise : 0, { currency: true })+"." : null}
                        </p>
                        </>
                    }
                /> 

                <CustomFormGroup
                    // mtprop="0rem"
                    formlabel="Previlege View Charge"
                    star="*"
                    FormField={
                        <>
                        <CustomCurrencyInput 
                        name="previlege_view_charge"
                        placeholder="Enter Previlege View Charge"
                        className="PricingInput"
                        decimalsLimit={2}
                        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                        prefix=" "
                        value={previlegeviewcharge}
                        onValueChange={(value) => value ? setPrevilegeViewCharge(value) : setPrevilegeViewCharge('')}
                        decimalSeparator="."
                        groupSeparator=","
                        />
                        <p className="towards-currency-input-text">
                            {previlegeviewcharge ? toWords.convert(previlegeviewcharge ? previlegeviewcharge : 0, { currency: true })+"." : null}
                        </p>
                        </>
                    }
                />                                                                  

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Status"
                    star="*"
                    FormField={
                        <CustomSingleSelectAutocomplete
                            options={InvStatus}
                            defaultValue={null}
                            inputLabel="Status"
                            inputVariant
                            inputname="inv_status"
                            inputRequired={true}
                            // inputRole="dynamic"
                            // onChange={}
                        />
                    }
                />                                                                                          
            
            </Grid>
            


           

            <Button variant="contained" type="submit" sx={{ margin: "1rem 0" }}>
              Add Inventory
            </Button>
          </Box>
        </Box>

      </Box>
    </>
  );
};

export default AddInventory;