// import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App';

// const systemdatetime = dayjs().format('YYYY-MM-DD HH:mm:ss');

const setAddProject = async(formdata) =>{

  // console.log(formdata.get("towerName"))
  
  try{
      const response = await Axios.post(`${API_URL}/projectname/addproject`, formdata);
      return response;

  }catch(err){

    return err;

  }
}

const getAllApartmentViewList = async() =>{
  try{

      const response = await Axios.post(`${API_URL}/apartmentview/getallapartmentviewlist`);
      return response;
  
  }catch(err){
  
      return err;
  
  }
}

const getAllUnitSizeList = async() =>{
  try{

      const response = await Axios.post(`${API_URL}/unitsize/getallunitsizelist`);
      return response;
  
  }catch(err){
  
      return err;
  
  }
} 

const getAllApartmentTypeList = async() =>{
  try{

      const response = await Axios.post(`${API_URL}/apartmenttype/getallapartmenttypelist`);
      return response;
  
  }catch(err){
  
      return err;
  
  }
} 

export { setAddProject, getAllApartmentViewList, getAllUnitSizeList, getAllApartmentTypeList }