export const ProjectName = [
    { value: 'Sheth Beaumonte', label: 'Sheth Beaumonte' },
    { value: 'Elixir Reserve', label: 'Elixir Reserve' },
    { value: 'Lodha Woods', label: 'Lodha Woods' },
    { value: 'Mahindra Alcove', label: 'Mahindra Alcove' },
    { value: 'Shapoorji Pallonji', label: 'Shapoorji Pallonji' },
    { value: 'Shapoorji The Minerva', label: 'Shapoorji The Minerva' },
];

export const InvStatus = [
    { value: 'Blocked', label: 'Blocked', color:'#0170c1' },
    { value: 'Reserved', label: 'Reserved', color:'#d71921' },
    { value: 'Sold', label: 'Sold', color:'#4f8337' },
    { value: 'Available', label: 'Available', color:'#ffffff'}
];

export const ServiceType = [
    { value: 'New Project', label: 'New Project' },
    { value: 'Resale', label: 'Resale' },
    { value: 'Rent', label: 'Rent' },
];

export const ProjectConfiguration = [
    { value: '1 BHK', label: '1 BHK' },
    { value: '2 BHK', label: '2 BHK' },
    { value: '3 BHK', label: '3 BHK' },
    { value: '4 BHK', label: '4 BHK' },
    { value: '5 BHK', label: '5 BHK' },
    { value: '6 BHK', label: '6 BHK' },
];

export const Locality = [
    { value: 'Dadar', label: 'Dadar' },
    { value: 'Thane', label: 'Thane' },
    { value: 'Bandra', label: 'Bandra' },
    { value: 'Mahim', label: 'Mahim' },
    { value: 'Ram Mandir', label: 'Ram Mandir' },
    { value: 'Byculla', label: 'Byculla' },
    { value: 'Andheri', label: 'Andheri' },
];

export const SubLocality = [
    { value: 'Prabhadevi', label: 'Prabhadevi' },
    { value: 'Worli', label: 'Worli' },
    { value: 'Century', label: 'Century' },
];

export const PropertyType = [
    { value: 'Residential', label: 'Residential' },
    { value: 'Commercial', label: 'Commercial' },
];

export const Blank = [
];

export const ResidentialPropertyCategory = [
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Bunglow', label: 'Bunglow' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'Farm House', label: 'Farm House' },
    { value: 'Independent House', label: 'Independent House' },
    { value: 'Pent House', label: 'Pent House' },
    { value: 'Service/Studio Apartments', label: 'Service/Studio Apartments' },
    { value: 'Villa', label: 'Villa' },
];

export const CommercialPropertyCategory = [
    { value: 'Commercial Office', label: 'Commercial Office' },
    { value: 'Commercial Shops', label: 'Commercial Shops' },
    { value: 'Commercial Showrooms', label: 'Commercial Showrooms' },
    { value: 'Commercial Space', label: 'Commercial Space' },
    { value: 'Hotels/Resorts', label: 'Hotels/Resorts' },
    { value: 'Office Space In It/Buisness Park', label: 'Office Space In It/Buisness Park' },
    { value: 'Commercial Warehouse', label: 'Commercial Warehouse' },
    { value: 'Godowns and Plots', label: 'Godowns and Plots' },
    { value: 'Factories', label: 'Factories' },
    { value: 'Plant and Machinery', label: 'Plant and Machinery' },
];

export const PropertyArea = [
    { value: 'Sq Feet', label: 'Sq Feet' },
    { value: 'Sq Meter', label: 'Sq Meter' },
    { value: 'Sq Yards', label: 'Sq Yards' },
    { value: 'Acres', label: 'Acres' },
    { value: 'Marla', label: 'Marla' },
    { value: 'Cents', label: 'Cents' },
    { value: 'Biggha', label: 'Biggha' },
    { value: 'Kottah', label: 'Kottah' },
    { value: 'Canals', label: 'Canals' },
    { value: 'Grounds', label: 'Grounds' },
    { value: 'Ares', label: 'Ares' },
    { value: 'Guntha', label: 'Guntha' },
    { value: 'Biswa', label: 'Biswa' },
    { value: 'Hectars', label: 'Hectars' },
    { value: 'Roods', label: 'Roods' },
    { value: 'Chataks', label: 'Chataks' },
    { value: 'Perch', label: 'Perch' },
];

export const PropertyPrice = [
    { value: '(₹) Rupee', label: '(₹) Rupee' },
    { value: 'AED', label: 'AED' },
    { value: '($) Dollar', label: '($) Dollar' },
    { value: '(€) Euro', label: '(€) Euro' },
    { value: '(฿) Thai Baht', label: '(฿) Thai Baht' },
    { value: '(₺) Turkish lira', label: '(₺) Turkish lira' },
];
export const Source = [
    { value: 'Google', label: 'Google' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Housing', label: 'Housing' },
    { value: 'MagicBrick', label: 'MagicBrick' }, 
    { value: 'OLX', label: 'OLX' },
    { value: 'Other', label: 'Other' },
];
export const LeadStatus = [
    { value: 'New Lead', label: 'New Lead' },
    { value: 'NI', label: 'NI' },
    { value: 'Hot', label: 'Hot' },
    { value: 'Warm', label: 'Warm' },
    { value: 'Lost', label: 'Lost' },
    { value: 'Rinning', label: 'Rinning' },
];
export const FollowUp = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];
export const Broker = [
    { value: 'Shubham', label: 'Shubham' },
    { value: 'Himanshu', label: 'Himanshu' },
    { value: 'Krishna', label: 'Krishna' },
    { value: 'Sagar', label: 'Sagar' },
];
export const Ccode = [
    { value: '91', label: '91' },
    { value: '971', label: '971' },
    { value: '120', label: '120' },
    { value: '145', label: '145' },
];
export const UserType = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Employee', label: 'Employee' },
];
export const BranchLocation = [
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Goa', label: 'Goa' },
];
export const UserRole = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Branch Admin', label: 'Branch Admin' },
    { value: 'Team Leader', label: 'Team Leader' },
    { value: 'Sales Manager', label: 'Sales Manager' },
    { value: 'Tele Caller', label: 'Tele Caller' },
    { value: 'Account', label: 'Account' },
    { value: 'HR', label: 'HR' },
];
export const BranchName = [
    { value: 'Dadar', label: 'Dadar' },
    { value: 'Main', label: 'Main' },
    { value: 'Mahim', label: 'Mahim' },
    { value: 'Andheri', label: 'Andheri' },
];
export const TeamLeader = [
    { value: 'Shubham Sonkar', label: 'Shubham Sonkar' },
    { value: 'Punit Parmar', label: 'Punit Parmar' },
    { value: 'Himanshu Pandey', label: 'Himanshu Pandey' },
];
export const SalesManager = [
    { value: 'Abhishek', label: 'Abhishek' },
    { value: 'Sagar', label: 'Sagar' },
    { value: 'Darshan', label: 'Darshan' },
];
export const TeleCaller = [
    { value: 'Tele Caller 1', label: 'Tele Caller 1' },
    { value: 'Tele Caller 2', label: 'Tele Caller 2' },
    { value: 'Tele Caller 3', label: 'Tele Caller 3' },
];