import axios from 'axios';
import { API_URL } from '../App';
const getLoginCheck = async() =>{
    try{
        // const response = await Axios.get('http://localhost:3004/loginCheck');
        const response = await axios.get(`${API_URL}/loginCheck`);
        return response;
    }catch(err){
        return err;
    }
  }

  const setLogin = async(data) =>{
    try{
        // const response = await Axios.post('http://localhost:3004/checkUser',{
        //     username: data.get('username'),
        //     password: data.get('password')
        // });
        
        const response = await axios.post(`${API_URL}/checkUser`,{username: data.get('username'),password: data.get('password')
        });
        return response;
    }catch(err){
        return err;
    }
  }

export {getLoginCheck, setLogin}
