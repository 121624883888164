import Axios from 'axios';
import { API_URL } from '../../App'; 

const getProjectInventory = async(project) =>{
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/getprojectinventory`, {
            project_name : project,
        });
        return response;
    
    }catch(err){
    
        return err;
     
    }
} 

const getProjectTowerList = async(project) =>{
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/getprojecttowerlist`, {
            project_name : project,
        });
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const getProjectWingList = async(project) =>{
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/getprojectwinglist`, {
            project_name : project,
        });
        return response;
    
    }catch(err){
    
        return err;
    
    }
}

const getProjectFloorList = async(project) =>{
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/getprojectfloorlist`, {
            project_name : project,
        });
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const getSingleInventory = async(data) =>{
    try{
        const response = await Axios.post(`${API_URL}/projectinventory/getsingleinventory`, {
            pinv_id : data,
        });
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

export {getProjectInventory, getProjectTowerList, getProjectWingList, getProjectFloorList, getSingleInventory}