import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
// import Select from "react-select";
import {
  Box,
  Typography,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RemoveIcon from '@mui/icons-material/Remove';
import {
//   ServiceType,
//   Locality,
//   SubLocality,
//   PropertyArea,
//   PropertyPrice,
//   PropertyType,
//   ResidentialPropertyCategory,
//   CommercialPropertyCategory,
//   FollowUp,
//   Ccode,
//   Blank,
} from "./../../data/data";
import {
//   CustomSelectFiled,
  CustomInputField,
//   CustomFormLabel,
  CustomFormGroup,
//   CustomTextareaField,
//   CustomMobileFiled,
//   CustomMultiSelectFiled
// CustomMuiTextField,
// CustomCurrencyInput,
CustomSingleSelectAutocomplete,
CustomMultiSelectAutocomplete,
Description,
} from "./../../Components/Common";
import "./AddProject.css";
// import { ToWords } from 'to-words';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { setAddProject, getAllApartmentViewList, getAllUnitSizeList, getAllApartmentTypeList } from "./ApiCalls";

const AddProject = () => {

    const navigate = useNavigate();

    const systemdatetime = dayjs().format('YYYY-MM-DD HH:mm:ss');

    const [towertype, setTowerType] = useState("Standalone");
    // const [ratepersqft, setRatePerSqFt] = useState();
    // const [agreementvalue, setAgreementValue] = useState();
    const [apartmentviewlist, setAllApartmentViewList] = useState([]);
    const [arealist, setAreaList] = useState([]);
    const [drybalconysizelist, setDryBalconySizeList] = useState([]);
    const [balconysizelist, setBalconySizeList] = useState([]);
    const [apartmenttypelist, setAllApartmentTypeList] = useState([]);

    // const toWords = new ToWords({
    //     localeCode: 'en-IN',
    //     converterOptions: {
    //       currency: true,
    //       ignoreDecimal: false,
    //       ignoreZeroCurrency: false,
    //       doNotAddOnly: false,
    //       currencyOptions: { // can be used to override defaults for the selected locale
    //         name: 'Rupee',
    //         plural: 'Rupees',
    //         symbol: '₹',
    //         fractionalUnit: {
    //           name: 'Paisa',
    //           plural: 'Paise',
    //           symbol: '',
    //         },
    //       }
    //     }
    // });

    const [towers, setTowers] = useState([
        {
            towerName:'',
            totalFloors:'',
            totalUnit:'',
            apartmentView:'',
            ratePerSqFt:'',
            isWing:'No',
            id:uuidv4(),
            configurations:[
                {
                    apartmentType:'',
                    unitSize:'',
                    dryBalconySize:'',
                    balconySize:'',
                    id:uuidv4(),
                },  
            ],
            wings:[
                {
                    wingName:'',
                    id:uuidv4(),
                },  
            ],
        },
    ]);

    // add tower element 
    const handleAddTower = () => {
        let _towers = [...towers];
        _towers.push({
            towerName:'',
            totalFloors:'',
            totalUnit:'',
            apartmentView:'',
            ratePerSqFt:'',
            isWing:'No',
            id:uuidv4(),
            configurations:[
                {
                    apartmentType:'',
                    unitSize:'',
                    dryBalconySize:'',
                    balconySize:'',
                    id:uuidv4(),
                },  
            ],
            wings:[
                {
                    wingName:'',
                    id:uuidv4(),
                },  
            ],
        })

        setTowers(_towers);
    }

    // remove tower element 
    const handleRemoveTower = (id: string) => {
        let _towers = [...towers];
        _towers = _towers.filter(tower=>tower.id!==id)
        setTowers(_towers);

    }

    // add configuration element
    const handleAddConfiguration = (id: string) => {
        const index = towers.findIndex((tower)=>tower.id===id)
        let _towers = [...towers];
        _towers[index].configurations.push({
            apartmentType:'',
            unitSize:'',
            dryBalconySize:'',
            balconySize:'',
            id:uuidv4(),
        })
        setTowers(_towers);
    }

    // remove configuration element 
    const handleRemoveConfiguration = (confiId: string) => {
        let _towers = [...towers];
        _towers.forEach(function(o) {
            o.configurations = o.configurations.filter(confi => confi.id !== confiId);
        });
        setTowers(_towers);
    }

    // add wing element
    const handleAddWing = (id: string) => {
        const index = towers.findIndex((tower)=>tower.id===id)
        let _towers = [...towers];
        _towers[index].wings.push({
            wingName:'',
            id:uuidv4(),
        })
        setTowers(_towers);
    }

    // remove wing element 
    const handleRemoveWing = (wingId: string) => {
        let _towers = [...towers];
        _towers.forEach(function(o) {
            o.wings = o.wings.filter(wing => wing.id !== wingId);
        });
        setTowers(_towers);
    }

    //handle tower data
	const handleTowerData = (
		id: string,
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const index = towers.findIndex((tower) => tower.id === id)
		let _towers = [...towers];
		_towers[index][event.target.name] = event.target.value
		setTowers(_towers)
	}

    //handle inner member data in team
	// const handleWingData = (
	// 	towerId: string,
	// 	wingId: string,
	// 	event: React.ChangeEvent<HTMLInputElement>,
	// ) => {
	// 	const towerIndex = towers.findIndex((tower) => tower.id === towerId)
	// 	let _towers = [...towers];
	// 	const wingIndex = towers[towerIndex].wings.findIndex(
	// 		(wing) => wing.id === wingId,
	// 	)
	// 	_towers[towerIndex].wings[wingIndex][event.target.name] =
	// 		event.target.value
	// 	setTowers(_towers)
	// } 

	//handle wing data in Tower
	const handleWingData = (
		towerId: string,
		wingId: string,
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const towerIndex = towers.findIndex((tower) => tower.id === towerId)
		let _towers = [...towers];
		const wingIndex = towers[towerIndex].wings.findIndex(
			(wing) => wing.id === wingId,
		)
		_towers[towerIndex].wings[wingIndex][event.target.name] =
			event.target.value
		setTowers(_towers)
	}    

    const handleAddProject = async (event) => {
        event.preventDefault();
        const formdata = new FormData(event.currentTarget);
        // formdata.append("towerdata", towers); 
        setAddProject(formdata)
        .then((response) => {
            // console.log(response);
            if (response.data === "Project Added") {

                toast.success("Project Name Added Succefully");
                
                setTimeout(() => { 
                    navigate("/project-name")
                }, 2000)    

            } else if(response.data === "Duplicate Project Entry") {
                toast.error("Failed! Project Name with same name already exist")
            } else if(response.data === "Minimum 2 Towers Required") {
                toast.error("Failed! Minimum 2 Towers Required")
            } else if(response.data === "Only 1 Tower Allow") {
                toast.error("Failed! Only 1 Tower Allow")
            } else if(response.data === "Minimum 2 Wings Required") {
                toast.error("Failed! Minimum 2 Wings Required")
            } else {
                toast.error("Failed! Abnormal Termination")
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {

        getAllApartmentViewList().then(response => {
            // console.log(response);
            if (response.data === "No Data Found") {
                setAllApartmentViewList(response.data.data)
            } else {
                setAllApartmentViewList(response.data.map((apartmentviewlist)=>({title: apartmentviewlist.apartment_view})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllUnitSizeList().then(response => {
            // console.log(response);
            if (response.data === "No Data Found") {
                setAreaList(response.data.data)
                setDryBalconySizeList(response.data.data)
                setBalconySizeList(response.data.data)
            } else {
                setAreaList(response.data.filter((arealist) => arealist.type === 'Flat')?.map((arealist)=>(arealist.unit_size)))
                setDryBalconySizeList(response.data.filter((drybalconysizelist) => drybalconysizelist.type === 'Dry Balcony')?.map((drybalconysizelist)=>(drybalconysizelist.unit_size)))
                setBalconySizeList(response.data.filter((balconysizelist) => balconysizelist.type === 'Balcony')?.map((balconysizelist)=>(balconysizelist.unit_size)))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllApartmentTypeList().then(response => {
            // console.log(response);
            if (response.data === "No Data Found") {
                setAllApartmentTypeList(response.data.data)
            } else {
                setAllApartmentTypeList(response.data.map((apartmenttypelist)=>(apartmenttypelist.apartment_type)))
            }
        }).catch(err => {
            console.log(err);
        });

    }, []);
    
    const MenuParentPages = [
        {MenuPage:'All Projects', MenuPagePath:'/project-name'}
    ]
    
    return (
    <>
      <ToastContainer />
      <Breadcrumb PageName="Add Project" MenuFunction={MenuParentPages} startIcon={<ArrowBackIosNewIcon />} btnName="Back" BtnRedirect="/project-name"/> 

      <Box p={1} component="form" autoComplete="off" onSubmit={handleAddProject} method="post">
        
        <Box className="custom-card">
          <Typography variant="h4" className="custom-card-head">
            Project Details :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>

                <input type="hidden" name="create_dt" value={systemdatetime}/>
                <input type="hidden" name="update_dt" value={systemdatetime}/>

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Project Name"
                    star="*"
                    FormField={
                            <CustomInputField
                                type="text"
                                name="projectName"
                                placeholder="Enter Project Name"
                                required="required"
                            />
                        }
                    />    
                
                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Builder Name"
                    star="*"
                    FormField={
                    <CustomInputField
                        type="text"
                        name="builderName"
                        placeholder="Enter Builder Name"
                        required="required"
                    />
                    }
                />

                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Featured Image"
                    star="*"
                    FormField={
                    <CustomInputField
                        type="file"
                        name="featuredImage"
                        placeholder="Enter Builder Name"
                        required="required"
                    />
                    }
                />
                <Grid mt={4} ml={2}>
                    <Description description="Note : Featured Image Size must be 1000X800" />
                </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
                <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Tower Type"
                    star="*"
                    FormField={
                    <RadioGroup
                    row
                    name="towerType"
                    value={towertype}
                    onChange={(event) => setTowerType(event.target.value)}
                    >
                    <FormControlLabel
                        value="Standalone"
                        control={<Radio />}
                        label="Standalone"
                    />
                    <FormControlLabel
                        value="Multiple"
                        control={<Radio />}
                        label="Multiple"
                    />
                    </RadioGroup>
                    }
                />                    
    
            </Grid>


            {towers.map((tower) => (
            <Grid container key={tower.id} mb={2}> 
                <Grid container spacing={2} mb={2}>
                    <Grid item md={12}>
                        <Typography variant="h4" className="custom-card-head" sx={{border:'none',p:0}}>
                            Tower Details :-
                        </Typography>
                    </Grid>  
                    <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Tower Name"
                    star="*"
                    FormField={
                        <>
                        <input type="hidden" name="towerId" value={tower.id} />
                        <CustomInputField
                                type="text"
                                name="towerName"
                                placeholder="Enter Tower Name"
                                onchange={(e) => handleTowerData(tower.id, e)}
                                value={tower.towerName}
                                required="required"
                            />
                        </>                            
                        }
                    /> 

                    <CustomFormGroup 
                    mtprop="0rem"
                    formlabel="Total Floors"
                    star="*"
                    FormField={
                        <CustomInputField
                                type="number"
                                name="totalFloors"
                                placeholder="Enter Total Floors Count"
                                onchange={(e) => handleTowerData(tower.id, e)}
                                value={tower.towerFloors}
                                required="required"
                            />
                        }
                    /> 

                    <CustomFormGroup 
                        mtprop="0rem"
                        formlabel="Total Units"
                        star="*"
                        FormField={
                            <CustomInputField
                                    type="number"
                                    name="totalUnits"
                                    placeholder="Enter Total Units Count"
                                    onchange={(e) => handleTowerData(tower.id, e)}
                                    value={tower.towerUnits}
                                    required="required"
                                />
                            }
                        />

                        <CustomFormGroup 
                            mtprop="0rem"
                            formlabel="Apartment View"
                            star="*"
                            FormField={
                                <CustomMultiSelectAutocomplete
                                  options={apartmentviewlist}
                                  inputLabel="Apartment View"
                                  inputPlaceholder="Select Views" 
                                  inputname="apartmentView"
                                  inputrequired={true}
                                />
                                // <CustomInputField
                                //         type="text"
                                //         name="apartmentView"
                                //         placeholder="Enter Apartment Views"
                                //         onchange={(e) => handleTowerData(tower.id, e)}
                                //         value={tower.apartmentView}
                                //         // required="required"
                                //     />
                            }
                        />

                        <CustomFormGroup 
                            mtprop="0rem"
                            formlabel="Rate Per Sq. Ft."
                            star="*"
                            FormField={
                            <>
                            <CustomInputField
                                type="number"
                                name="ratePerSqFt"
                                placeholder="Enter Rate Per Sq. Ft."
                                onchange={(e) => handleTowerData(tower.id, e)}
                                value={tower.ratePerSqFt}
                                required="required"
                            />
                            {/* <CustomCurrencyInput 
                                name="ratePerSqFt"
                                placeholder="Enter Rate Per Sq. Ft."
                                className="PricingInput"
                                decimalsLimit={2}
                                intlConfig={{ locale: 'en-IN', currency: 'INR' }}
                                prefix=" "
                                value={ratepersqft}
                                onValueChange={(value) => value ? setRatePerSqFt(value) : setRatePerSqFt('')}
                                decimalSeparator="."
                                groupSeparator=","
                            />
                            <p className="towards-currency-input-text">
                                {ratepersqft ? toWords.convert(ratepersqft ? ratepersqft : 0, { currency: true })+"." : null}
                            </p>  */}
                            </>
                            }
                        />
                    
                    </Grid>

                    {tower.configurations.map((confi) => (
                    <Grid container spacing={2} mt={0} mb={2} key={confi.id}>  
                        <Grid item md={2}>
                            <Typography variant="h4" className="custom-card-head custom-confi-head">
                                Configurations
                            </Typography>
                        </Grid>
                        <input type="hidden" name="configurationTowerId" value={tower.id} />
                        <Grid item md={2}>
                            <CustomSingleSelectAutocomplete
                                // options={['1BED','2BED']}
                                options={apartmenttypelist}
                                defaultValue={null}
                                inputLabel="Apartment"
                                inputVariant
                                inputname="apartmentType"
                                inputRequired={true}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CustomSingleSelectAutocomplete
                                options={arealist}
                                defaultValue={null}
                                inputLabel="Area (sq.ft.)"
                                inputVariant
                                inputname="unitSize"
                                inputRequired={true}
                            /> 
                        </Grid>
                        <Grid item md={3}>
                            <CustomSingleSelectAutocomplete
                                options={drybalconysizelist}
                                defaultValue={null}
                                inputLabel="Dry Balcony (sq.ft.)"
                                inputVariant
                                inputname="dryBalconySize"
                                inputRequired={true}
                            /> 
                        </Grid>
                        <Grid item md={3}>
                            <CustomSingleSelectAutocomplete
                                options={balconysizelist}
                                defaultValue={null}
                                inputLabel="Balcony (sq.ft.)"
                                inputVariant
                                inputname="balconySize"
                                inputRequired={true}
                            /> 
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={10}>
                            {tower.configurations.length > 1 && (
                                <Button variant="outlined" size="medium" color="error"
                                onClick={()=>handleRemoveConfiguration(confi.id)}
                                sx={{marginRight:'15px'}} ><RemoveIcon /></Button>   
                            )}
                           <Button variant="outlined" size="medium"
                            onClick={()=>handleAddConfiguration(tower.id)}
                            ><AddIcon /></Button> 
                        </Grid>
                    </Grid>
                    ))} 

                    <Grid container spacing={2} mb={2}>   
                        <CustomFormGroup 
                            mtprop="0rem"
                            formlabel="Wing"
                            star="*"
                            FormField={
                            <RadioGroup
                            row
                            name="isWing"
                            onChange={(e) => handleTowerData(tower.id, e)}
                            value={tower.isWing}
                            >
                            <input type="hidden" name="is_Wing" value={tower.isWing} /> 
                            <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                            />
                            </RadioGroup>
                            }
                        /> 
                </Grid>
                
                { tower.isWing==='Yes' && (
                   tower.wings.map((wing) => (
                    <Grid container spacing={2} mb={2} key={wing.id}> 
                        <Grid item xs={6} md={2} className="custom-from-group" sx={{marginTop:'0rem'}}>
                            <Typography variant="h6" className="custom-form-label">
                            Wing Name <span className="required-label">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <input type="hidden" name="wingTowerId" value={tower.id} />
                            {/* <input type="hidden" name="wingId" value={wing.id} /> */}
                            <CustomInputField
                                type="text"
                                name="wingName"
                                placeholder="Enter Wing Name"
                                onchange={(e) => handleWingData(tower.id, wing.id, e)}
                                value={wing.wingName}
                                required="required"
                            />
                        </Grid> 
                        {tower.wings.length > 1 && (
                        <Grid item xs={6} md={1}>
                                <Button variant="outlined" size="medium" color="error"
                                onClick={()=>handleRemoveWing(wing.id)}
                                ><RemoveIcon /></Button> 
                            </Grid>  
                        )}
                        
                        <Grid item xs={6} md={1}>
                            <Button variant="outlined" size="medium"
                            onClick={()=>handleAddWing(tower.id)}
                            ><AddIcon /></Button> 
                        </Grid> 
                            
                    </Grid>
                   )
                 ))}
                
                {towers.length > 1 && (
                <Grid item xs={6} md={2} mb={2}>
                    <Button variant="outlined" size="medium" color="error"
                    onClick={()=>handleRemoveTower(tower.id)}
                    > <RemoveIcon /> Tower
                    </Button> 
                </Grid>
                )}
                
                {towertype==='Multiple' && (
                <Grid item xs={6} md={2} mb={2}>
                    <Button variant="outlined" size="medium"
                    onClick={handleAddTower}
                    ><AddIcon /> Tower </Button> 
                </Grid> 
                )}
            </Grid> 
            ))}

            <Button variant="contained" type="submit" sx={{ margin: "1rem 0" }}>
              Add Project
            </Button>
          </Box>
        </Box>

      </Box>
    </>
  );
};

export default AddProject;