import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
import "./CustomModal.css";

// import AddIcon from "@mui/icons-material/Add";

const CutomModal = (props) => {
    return (
        <> 
            {/* {props.BtnName ?
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddModalfun}
            >
                {props.BtnName}
            </Button> 
            : null } 

            {props.AddCountryBtnName ?  
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddCountryModalfun}
            >
                {props.AddCountryBtnName}
            </Button>
            :null}

            {props.AddStateBtnName ?
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddStateModalfun}
            >
                {props.AddStateBtnName}
            </Button>
            :null}
            {props.AddCityBtnName ?
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddCityModalfun}
            >
                {props.AddCityBtnName}
            </Button>
            :null}

            {props.AddLocalityBtnName ?
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddLocalityModalfun}
            >
                {props.AddLocalityBtnName}
            </Button>
            :null}

            {props.AddSubLocalityBtnName ?
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddSubLocalityModalfun}
            >
                {props.AddSubLocalityBtnName}
            </Button>
            :null} */}

            {/* <Button
                variant="contained"
                color="success"
                sx={{ mx: 1 }}
                startIcon={<UploadFileIcon />}
                onClick={props.OpenImportModalfun}
            >
                {props.ImportBtnName}
            </Button> */}

            <Modal
                open={props.open}
                onClose={props.onClosefun}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal_div" sx={props.InlineCss}>
                    <Box className="modal-header">
                        <Typography className="modal-title" variant="h3">{props.ModalTitle}</Typography>
                        <button type="button" className="modal-close-btn" onClick={props.handeleclosefun}><span>×</span></button>
                    </Box>
                    <Box p={2} sx={props.ModalContentCss}>
                        {props.ModalContent}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CutomModal